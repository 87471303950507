.list-tag {
    height: calc(100vh - 235px);
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow-y: scroll;
}

.item-tag {
    margin-right: 8px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-size: 18px;
    gap: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.item-tag.active {
    background-color: #b2b8bb;
}

.item-tag:hover {
    cursor: pointer;
    background-color: #ccc;
}

.item-tag:hover .icon-action {
    display: block;
}

.icon-action {
    display: none;
    margin-left: auto;
}