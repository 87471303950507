.custom_image_picker .ant-row.ant-form-item-row {
    align-items: center;
}

.custom_image_picker .ant-upload-list.ant-upload-list-picture-card {
    justify-content: center;
}

.custom_image_picker .ant-form-item-explain-error,
.custom_image_picker .ant-form-item-control-input {
    text-align: center;
}
