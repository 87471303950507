.custom_upload_image .ant-upload-list-item.ant-upload-list-item-undefined {
    width: 150px !important;
    height: 150px !important;
}

.custom_upload_image .ant-upload-list-item-thumbnail {
    width: 100% !important;
    height: 100% !important;
}

.custom_upload_image .ant-upload-list-item-container {
    display: flex;
    justify-content: center;
}