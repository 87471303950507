.custom-badge .ant-badge .ant-badge-multiple-words {
    padding: 0 4px;
}

.custom-badge .ant-badge .ant-badge-count {
    top: -5px;
}

.custom-badge2 .ant-badge .ant-badge-count {
    top: 5px;
    right: -10px;
}
